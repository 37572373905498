<template>

  <div>
    <rxNavBar :title="navTitle"></rxNavBar>
    <div class="applyTime">申请时间：&nbsp;{{ houseInfo.addTime }}</div>
    <div class="headerBlock">
      <div class="wait-approve">{{ houseInfo.approvalStatusStr }}</div>
      <div class="houseAddress">{{ houseInfo.roomDetailedAddress }}</div>
      <div class="keyAddress">
        {{ houseInfo.estateName }}
        <span class="content-divide-small">|</span>
        {{ houseInfo.estateName }}
      </div>
    </div>

    <div class="displayBlock" v-if="picList.length!=0">
      <div class="swiper_show" ref="wrapperBox">
        <div class="content">
          <div class="imgWrap" v-for="(url,i) in picList" @click="previewImg(i)">
            <van-image
                width="90"
                height="78"
                fit="contain"
                radius="10"
                :src=url
            />
          </div>
          <div class="num">
            {{ picList.length }}图
          </div>
        </div>

      </div>
    </div>

    <div class="displayBlock">
      <table class="houseInfo">
        <tr>
          <td>
            <div class="boldFont">交房：<span class="normalFont">{{ houseInfo.giveHouseTime }}</span></div>
          </td>
          <td>
            <div class="boldFont">装修预算：<span class="normalFont">{{ houseInfo.maxBudgetFee }}元</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="boldFont">装修类型：<span class="normalFont">{{ houseInfo.renovationType }}</span></div>
          </td>
          <td>
            <div class="boldFont">承担方：<span class="normalFont">{{ houseInfo.costBear }}</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="boldFont">房屋类型：
              <span v-if="houseInfo.houseType=='0'" class="normalFon orangeColor">整租</span>
              <span v-if="houseInfo.houseType=='1'" class="normalFon orangeColor">合租</span>
              <span v-if="houseInfo.houseType=='2'" class="normalFon orangeColor">集寓</span>

            </div>
          </td>

          <td>
            <div class="boldFont">户型改造：<span
                class="normalFont ">{{ houseInfo.bedroomCount ? houseInfo.bedroomCount : '0' }}+{{ houseInfo.changeCount ? houseInfo.changeCount : '0' }}房间</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="boldFont">收房期限：<span
                class="normalFont orangeColor">{{ houseInfo.contractYears ? houseInfo.contractYears + '年' : '暂无' }}</span>
            </div>
          </td>
          <td>
            <div class="boldFont">面积：<span
                class="normalFont">{{ houseInfo.obuiltUpArea ? houseInfo.obuiltUpArea + 'm²' : '暂无' }}</span></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="displayBlock">
      <div class="boldFont descTitle">装修需求:</div>
      <div class="normalFont desc-Text">
        {{ houseInfo.renovationNeed }}
      </div>
    </div>
    <div class="displayBlock">
      <div class="boldFont descTitle">装修描述:</div>
      <div class="normalFont desc-Text">
        {{ houseInfo.afterRentDesprition }}
      </div>
    </div>
    <div v-if="auditOrCheck">
      <div class="return-audit">
        <span>审核反馈</span>
      </div>
      <div class="normalBlock">
        <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
        <span class="blockTitle">审核结果</span>
        <div class="rightText">
          <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
          <span class="content-divide"> | </span>
          <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
        </div>
      </div>
      <!--        <div class="normalBlock" v-show="isPass" >-->
      <!--            <span class="maker" :class="{'orangeColor':(guidingPrice!='')}">• </span>-->
      <!--            <span class="blockTitle">指导价</span>-->
      <!--            <span class="content-divide">|</span>-->
      <!--            <input  type="number" class="inputPrice" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="guidingPrice" >-->
      <!--            <span class="priceUnit">元</span>-->
      <!--        </div>-->

      <div id="addressPanel" class="part-inputpart-row" v-show="isReject" @click="isReasonShow = !isReasonShow">
        <span class="maker" :class="{'orangeColor':rejectReason.dictionaryTitle}">• </span>
        <span class="part-inputpart-row-header">原因</span>
        <span class="content-divide">|</span>
        <span class="part-inputpart-row-graytext"
              :class="rejectReason.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{ rejectReason.dictionaryTitle }}</span>
        <img class="part-inputpart-row-right"
             :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
             src="../../../assets/images/triangle.png">
      </div>
      <!--            <div>-->
      <!--                <div class="part-inputpart part-inputpart-dropDownList" v-if="isReasonShow">-->
      <!--                    <div class="part-inputpart-dropDownList-option" :class="item==rejectReason ? 'part-inputpart-dropDownList-option-selected' : '' "-->
      <!--                         v-for="(item,index) in refuseReasonList" :key="index" @click="selectAddressOption($event,index)">{{ item.dictionaryTitle}}</div>-->
      <!--                </div>-->
      <!--            </div>-->
      <div class="displayBlock">
        <div><span class="suggestTitle">审批意见</span></div>
        <div><textarea v-model="houseInfo.approvalOpinion" class="textArea"></textarea></div>
      </div>
      <div class="rule"></div>
      <van-button class="saveButton_Disable"
                  :class="{'saveButton_Enable': isPass  || (isReject && rejectReason.dictionaryTitle)}"
                  :disabled=" !((isPass ) || (isReject && rejectReason.dictionaryTitle)) || enable" @click="save">保存
      </van-button>
    </div>

    <van-popup v-model="isReasonShow" round position="bottom">
      <van-picker
          show-toolbar
          :columns="refuseReasonList"
          @cancel="isReasonShow = false"
          :default-index="defaultIndex"
          value-key="dictionaryTitle"
          @confirm="onConfirm"
      />
    </van-popup>

  </div>

</template>

<script>


import {
  NavBar,
  Button,
  ImagePreview,
  Image as VanImage, Picker, Popup
} from 'vant';
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import BScroll from "better-scroll";
import {decorationDetailInit, decorationAgree, decorationRefuse} from "../../../getData/getData";
import {getStaffId,responseUtil} from "../../../libs/rongxunUtil";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [BScroll.name]: BScroll,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    rxNavBar
  },
  data() {
    return {
      //头部标题
      navTitle: '装修申请',
      //是否隐藏审核部分
      auditOrCheck: true,
      istype: 0,
      refuseId: '',
      isReasonSelect: false,
      rejectReason: '请选择',
      defaultIndex: '',
      keyAddressArr: [],
      //拒绝理由
      refuseReasonList: [],
      guidingPrice: "",
      isPass: false,
      isReject: false,
      isSelect: '',
      isReasonShow: false,
      houseInfo: {},
      picList: [],
      enable:false,
    }
  },
  created: function () {
    this.initData();
  },
  methods: {

    onConfirm(value, index) {
      this.rejectReason = value
      this.defaultIndex = index
      this.isReasonShow = false
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },

    previewImg(start) {
      ImagePreview(
          {
            images: this.picList,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    //通过
    choosePass() {
      this.istype = 1;
      this.isPass = true;
      this.isReject = false;

    },
    //驳回
    chooseReject() {
      this.istype = 2;
      this.isReject = true;
      this.isPass = false;
    },
    save() {
      var that = this;
      var func;
      var data = {}
      //通过的接口
      if (this.istype == "1") {
        func = decorationAgree;
        //指导价
        console.log(this.guidingPrice)

      }
      //拒绝的接口
      if (this.istype == "2") {
        func = decorationRefuse;
        //拒绝理由id
        data.refuseReason_id = that.rejectReason.id;
      }
      data.id = that.houseInfo.id
      data.staff_id = getStaffId()//that.houseInfo.approvalApplicantStaff_id
      data.approvalOpinion = that.houseInfo.approvalOpinion
      console.log(data.approvalOpinion)
      data.rtid = that.houseInfo.rtid
      data.type = "2"
      that.enable=true
      func(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data)
          responseUtil.alertMsg(that, response.data.msg)
          that.enable = false
          that.leftReturn();
        })
      })
    },
    selectAddressOption(event, index) {
      this.rejectReason = event.path[0].childNodes[0].data
      this.isReasonShow = false
      this.isReasonSelect = true
      this.refuseId = this.refuseReasonList[index].id
    },
    clickAddressShow() {
      this.isReasonShow = !this.isReasonShow;
      var panel = document.getElementById('addressPanel')
      document.addEventListener('click', e => {
        if (!panel.contains(e.target)) {
          this.isReasonShow = false
        }
      })
    },
    initData() {
      let that = this
      //如果是点击查看进入本页面，则隐藏审核按钮
      if (this.$route.params.afterRentStatus) {
        that.auditOrCheck = false
        that.navTitle = '查看详情'
      }
      let initData = {}
      //initData.id="70"
      // 接收参数
      initData.type = "2";
      initData.id = this.$route.params.id
      console.log('id=' + this.$route.params.id)
      decorationDetailInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data);
          that.refuseReasonList = response.data.data.refuseReasonList;
          that.houseInfo = response.data.data.item;
          that.houseInfo.approvalOpinion = '';
          let list = response.data.data.picList;
          if (list) {
            for (let i = 0; i < list.length; i++) {
              that.picList.push(list[i].path)
              that.$nextTick(() => {
                if (!that.scroll) {
                  that.scroll = new BScroll(that.$refs.wrapperBox, {
                    scrollX: true,
                    eventPassthrough: 'vertical'
                  })
                }
              })
            }
          }

        })
      })
    },
  },
}
</script>

<style lang="less">

// 图片墙
.swiper_show {
  width: 9rem;
  position: relative;
  margin: 10px 10px;
  height: 78px;

  .content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;

    .imgWrap {
      margin-right: 10px;
    }
  }

  .num {
    position: absolute;
    left: 8px;
    top: 15px;
    font-size: 10px;
    background-color: rgb(0, 0, 0);
    opacity: .2;
    color: rgb(255, 255, 255);
    padding: 2px 6px;
    display: inline-block;
    border-radius: 10px;
    font-weight: bolder;
    line-height: 10px;
  }
}


.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}


.part-inputpart-dropDownList {
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -17px;

}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 15px;
  width: 17%;
}


.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 15px;
}

.return-audit {
  font-size: 13px;
  color: red;
  margin: 26px 18px 4px;
  padding: 0;

}

.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  margin: 14px auto;
}

.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.content-divide-small {
  font-size: 14px;
  // color: #9a9a9a;
  margin: 0 5px;
}

.selectReason {
  width: 245px;
  height: 1rem;
  line-height: 28px;
  margin-left: 20px;
  font-size: 14px;
  border-style: none;
}

.suggestTitle {
  margin-left: 14px;
  margin-top: 12px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.textArea {
  resize: none;
  padding: 10px;
  margin: 39px auto 20px;
  height: 100px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: rgba(182, 177, 177, 0.11);
}

.priceUnit {
  color: rgba(153, 153, 153, 0.45);
  font-size: 14px;
  float: right;
  padding-right: 31px;
}

.inputPrice {
  color: #ff3c00;
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 15px;
  //font-weight: bold;
  border: none;
}

.divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.passText {
  flex: 1;
  padding-right: 7px;
}

.rejectText {
  padding-left: 7px;
  padding-right: 33px;

}

.rightText {
  margin-left: auto;
  font-size: 15px;
  color: #999999;
}

.maker {
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}

.wait-approve {
  width: 46px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
  color: white;
  border-radius: 8px 0;
  background: linear-gradient(to right, #ffb96f, #ff6d44);
}

.orangeColor {
  color: #ff3c00;
}

.choosedStyle {
  color: #ff3c00;
  font-weight: bold;
}

.normalFont {
  font-size: 13px;
  font-weight: normal;
  color: #999;
}

.blockTitle {
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}

.boldFont {
  font-size: 14px;
  font-weight: bold;

  span {
    /*font-size: 13px;*/
    font-weight: 400;
  }
}

.descTitle {
  height: 25px;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 20px;
}

.desc-Text {
  height: auto;
  padding-left: 14px;
  padding-bottom: 17px;
  padding-right: 20px;
}

.houseInfo {
  padding-top: 14px;
  padding-bottom: 14px;
  width: 9.2rem;
  padding-left: 14px;
}

.applyTime {
  font-size: 12px;
  text-align: right;
  color: #999999;
  padding-right: 15px;
  /*margin-top: 50px;*/
  margin-bottom: 6px;
}

.houseAddress {
  font-size: 16px;
  font-weight: bold;
  height: auto;
  padding-top: 8px;
  //padding-bottom: -2px;
  padding-left: 15px;
}

.keyAddress {
  font-size: 13px;
  height: auto;
  color: #999;
  padding-top: 7px;
  padding-bottom: 17px;
  padding-left: 15px;

}

.headerBlock {
  background-color: white;
  border-radius: 6px;
  width: 9.2rem;
  height: available;
  margin: 0px auto 26px;
}

.nav-bar {
  background-color: #f8f8f8;
  border: none;
  font-weight: bold;
}

.saveButton_Disable {

  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 92%;
  height: 1.22rem;
  display: block;
  border-radius: 8px;
  margin: 10px auto 18px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}

.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

}

.middle {
  overflow: hidden;
  height: auto;
}

.displayBlock {
  //position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  height: auto;
  margin: 15px auto;
}

.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  line-height: 1rem;
  height: 50px;
  margin: 5px auto 14px;
}

.rule {
  width: 98%;
  border: 1px solid #efefef;
  margin-top: 50px;
}

</style>
